import axios from "axios";
import _ from "lodash";

export const state = {
    postTotalCnt: 0,
    postVuexList: [],
    postVuexAnalyticsInfo: [],
    postDetail: {},
    surveyContentList: []
}

export const mutations = {
    setPostTotalCnt(state, data) {
        state.postTotalCnt = data
    },

    setPostList(state, data) {
        state.postVuexList = data
        state.postVuexList.forEach(
            (row) => {
                row.selected = false
            }
        )
        // console.log("POST STORE Mutations (postVuexList) :", state.postVuexList)
    },

    setPostAnalyticsInfo(state, data) {
        state.postVuexAnalyticsInfo = data
    },

    setVoteList(state, data) {
        state.postVuexList = data
    },

    setResetPostList(state) {
        state.postTotalCnt = 0
        state.postVuexList = []
    },

    setPostDetail(state, data) {
        state.postDetail = data
    },

    setSurveyContentList(state, data) {
        state.surveyContentList = data
    }
}

export const actions = {
    async getPostingList(state, {payload}) {
        console.log(payload)

        let url = `/api/post/admin/getListAllBoardByFilter/${payload.page}/${payload.callCnt}/regDESC`

        await axios.post(
            url,
            payload.condition,
            {
                headers: {
                    accessToken: state.rootState.accountStore.accountVuexInfo.accessToken,
                    refreshToken: state.rootState.accountStore.accountVuexInfo.refreshToken
                },
            })
            .then(async (res) => {
                console.log(res.data)
                state.commit('setPostList', [])
                state.commit('setVoteList', [])
                state.commit('setPostTotalCnt', 0)

                if (res.data.count != 0) {
                    state.commit('setPostList', res.data.post_list)
                    state.commit('setVoteList', res.data.post_list)
                    state.commit('setPostTotalCnt', res.data.count)
                }

            })
            .catch((err) => {
                    console.log("getPostingList Error :", err)
                    if (!_.isEmpty(err.response)) {
                        if (err.response.status === 401) {
                            state.commit("accountStore/setCheckAuth", false, {root: true})
                        }
                    }
                }
            )
    },
    async callForExcelDown(state, {payload}) {
        console.log(payload)

        let url = `/api/post/admin/excel/getListAllBoardByFilter/regDESC`

        axios({
            method: 'POST',
            url: url,
            responseType: 'blob',
            headers: {
                accessToken: state.rootState.accountStore.accountVuexInfo.accessToken,
                refreshToken: state.rootState.accountStore.accountVuexInfo.refreshToken
            },
            data: payload.condition,
        })
            .then(response =>{
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'posting_list.xlsx');
                document.body.appendChild(link);
                link.click();
            })

    },
    async getAnalyticsInfo(state, {payload}) {
        console.log(payload)

        let url = `/api/post/admin/agg/getMemberDocumentCntListByFilter/${payload.page}/${payload.callCnt}`

        await axios.post(
            url,
            payload.condition,
            {
                headers: {
                    accessToken: state.rootState.accountStore.accountVuexInfo.accessToken,
                    refreshToken: state.rootState.accountStore.accountVuexInfo.refreshToken
                },
            })
            .then(async (res) => {
                console.log(res.data)
                state.commit('setPostAnalyticsInfo', [])
                state.commit('setPostTotalCnt', 0)

                if (res.data.count != 0) {
                    state.commit('setPostAnalyticsInfo', res.data.info_list)
                    state.commit('setPostTotalCnt', res.data.count)
                }

            })
            .catch((err) => {
                    console.log("getPostingList Error :", err)
                    if (!_.isEmpty(err.response)) {
                        if (err.response.status === 401) {
                            state.commit("accountStore/setCheckAuth", false, {root: true})
                        }
                    }
                }
            )
    },
    async callForExcelDownForAnalytics(state, {payload}) {
        console.log(payload)

        let url = `/api/post/admin/agg/excel/getMemberDocumentCntListByFilter`

        axios({
            method: 'POST',
            url: url,
            responseType: 'blob',
            headers: {
                accessToken: state.rootState.accountStore.accountVuexInfo.accessToken,
                refreshToken: state.rootState.accountStore.accountVuexInfo.refreshToken
            },
            data: payload.condition,
        })
            .then(response =>{
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'posting_rank.xlsx');
                document.body.appendChild(link);
                link.click();
            })
    },
}
