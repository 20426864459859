import {mapMutations, mapState} from "vuex";

export const accountStore = {
    ...mapState('accountStore', {
        accountVuexInfo: (state) => state.accountVuexInfo,
        checkAuth: (state) => state.checkAuth,
    })
}

export const accountMutations = mapMutations('accountStore', [
    "setCheckAuth",
    "setAccountVuexInfo"
])
