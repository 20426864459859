import {mapActions, mapMutations, mapState} from "vuex";

export const allPerposeStore = {
    ...mapState('allPerposePostStore', {
        // accountVuexInfo: (state) => state.accountVuexInfo,
        generalRowInfo: (state) => state.generalRowInfo,
        boardCategoryList: (state) => state.boardCategoryList,
        boardCategoryAddOrEdit: (state) => state.boardCategoryAddOrEdit,
        boardCategoryInfo: (state) => state.boardCategoryInfo,
        popupVuexInfo: (state) => state.popupVuexInfo,
        bannerVuexInfo: (state) => state.bannerVuexInfo,
    })
}

export const allPerposeMutations = mapMutations('allPerposePostStore', [
    'setGeneralRowInfo',
    'setBoardCategoryList',
    'setBoardCategoryAddOrEdit',
    'setBoardCategoryInfo',
    'setPopupVuexInfo',
    'setBannerVuexInfo'
])

export const allPerposeActions = mapActions('allPerposePostStore', [
    'getBoardCategoryList',
    'postBoardCategory',
    'editBoardCategory',
    'delBoardCategory',
])
