export const state = {
    checkAuth: false,
    accountVuexInfo: {}
}

export const mutations = {
    setCheckAuth(state, val) {
        state.checkAuth = val
    },

    setAccountVuexInfo(state, val) {
        state.accountVuexInfo = val
    }
}

export const actions = {}
