import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import vco from "v-click-outside";

import App from "./App.vue";
import router from "./router";
import VueFeather from "vue-feather";
import VueApexCharts from "vue-apexcharts";
import Vuelidate from "vuelidate";
import VueEasyLightbox from "vue-easy-lightbox";
import VueAlertify from "vue-alertify";
import VueMask from "v-mask";
import VueQuillEditor from "vue-quill-editor";
import * as VueGoogleMaps from "vue2-google-maps";
import VueVectorMap from "vuevectormap";
import Meta from "vue-meta";

import store from "@/state/store";

Vue.config.productionTip = false;

import "@/assets/scss/app.scss";

import DOMPurify from 'dompurify'

Vue.directive("purify-html", (el, binding) => {
  el.innerHTML = DOMPurify.sanitize(binding.value);
});

Vue.directive('numericOnly', {
  bind(el) {
    el.addEventListener('input', function() {
      this.value = this.value.replace(/[^0-9]/g, '');
    });
  }
});

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css"

Vue.use(DatePicker)
Vue.use(VueFeather);
Vue.use(BootstrapVue);
Vue.use(vco);
Vue.component("apexchart", VueApexCharts);
Vue.use(Vuelidate);
Vue.use(VueEasyLightbox);
Vue.use(VueAlertify);
Vue.use(VueMask);
Vue.use(VueQuillEditor);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places"
  },
  installComponents: true
});
Vue.use(VueVectorMap);
Vue.use(Meta);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

import vueCookies from "vue-cookies";
import VueCryptojs from "vue-cryptojs/src";

Vue.use(vueCookies);
Vue.$cookies.config("7d");

Vue.use(VueCryptojs);

import "tui-image-editor/dist/tui-image-editor.css";
import axios from "axios";

// axios.defaults.baseURL = "https://api.critalk.com";
// axios.defaults.baseURL = "http://192.168.0.254:8980";
axios.defaults.baseURL = "https://api.ssoma.kr";

import VueHtml2Canvas from "vue-html2canvas";
Vue.use(VueHtml2Canvas);


