import axios from "axios";
import _ from "lodash";

export const state = {
    generalRowInfo: {},
    boardCategoryList: [],
    boardCategoryAddOrEdit: "",
    boardCategoryInfo: {},

    popupVuexInfo: {
        edate: '',
        sdate: '',
        external_link: '',
        image_url: '',
        link_url: '',
        title: ''
    },
    bannerVuexInfo: {
        edate: '',
        sdate: '',
        external_link: '',
        image_url: '',
        link_url: '',
        title: ''
    },
}

export const mutations = {
    setGeneralRowInfo(state, val) {
        state.generalRowInfo = val
    },

    setBoardCategoryList(state, val) {
        state.boardCategoryList = val
    },

    setBoardCategoryAddOrEdit(state, val) {
        state.boardCategoryAddOrEdit = val
    },

    setBoardCategoryInfo(state, val) {
        state.boardCategoryInfo = val
    },

    setPopupVuexInfo(state, val) {
        state.popupVuexInfo = val
    },

    setBannerVuexInfo(state, val) {
        state.bannerVuexInfo = val
    }
}

export const actions = {
    getBoardCategoryList(state) {
        let url = `/api/category/0/${state.rootState.loggedInfo.logged_info.mart_srl}`

        // console.log(state.rootState.loggedInfo.logged_info.mart_srl)

        axios.get(
            url,
            {
                headers: {
                    accessToken: state.rootState.accountStore.accountVuexInfo.accessToken,
                    refreshToken: state.rootState.accountStore.accountVuexInfo.refreshToken
                },
            })
            .then((res) => {
                // console.log("df :",res)
                // this.newsCategoryList = res.data.post_category_list
                state.commit('setBoardCategoryList', res.data.sort((a, b) => a.list_order - b.list_order))
                // state.commit('setBoardCategoryList', res.data)
                // console.log(state.state.boardCategoryList)
            })
            .catch((err) => {
                console.log("getBoardCategoryList Error :", err)
                if (!_.isEmpty(err.response)) {
                    if (err.response.status === 401) {
                        state.commit("accountStore/setCheckAuth", false, {root: true})
                    }
                }
            })
    },

    postBoardCategory(state, {payload}) {
        let url = `/api/postCategory/admin/insert`

        let postData = {
            module_srl: payload.moduleSrl,
            title: payload.title
        }

        axios.post(
            url,
            postData,
            {
                headers: {
                    accessToken: state.rootState.accountStore.accountVuexInfo.accessToken,
                    refreshToken: state.rootState.accountStore.accountVuexInfo.refreshToken
                },
            })
            .then((res) => {
                console.log(res.data)

                if (res.data.message === 'success') {
                    state.dispatch('getBoardCategoryList', {
                        payload: {
                            moduleSrl: payload.moduleSrl
                        }
                    })
                }
            })
            .catch((err) => {
                console.log("postBoardCategory Error :", err)
                if (!_.isEmpty(err.response)) {
                    if (err.response.status === 401) {
                        state.commit("accountStore/setCheckAuth", false, {root: true})
                    }
                }
            })
    },

    editBoardCategory(state, {payload}) {
        let url = `/api/postCategory/admin/update`

        let putData = {
            module_srl: payload.moduleSrl,
            category_srl: payload.categorySrl,
            title: payload.title
        }

        axios.put(
            url,
            putData,
            {
                headers: {
                    accessToken: state.rootState.accountStore.accountVuexInfo.accessToken,
                    refreshToken: state.rootState.accountStore.accountVuexInfo.refreshToken
                },
            })
            .then((res) => {
                console.log(res.data)
                if (res.data.message === 'success') {
                    state.dispatch('getBoardCategoryList', {
                        payload: {
                            moduleSrl: payload.moduleSrl
                        }
                    })
                }
            })
            .catch((err) => {
                console.log("editBoardCategory Error :", err)
                if (!_.isEmpty(err.response)) {
                    if (err.response.status === 401) {
                        state.commit("accountStore/setCheckAuth", false, {root: true})
                    }
                }
            })
    },

    delBoardCategory(state, {payload}) {
        let url = `/api/postCategory/admin/delete/${payload.category_srl}`

        axios.delete(
            url,
            {
                headers: {
                    accessToken: state.rootState.accountStore.accountVuexInfo.accessToken,
                    refreshToken: state.rootState.accountStore.accountVuexInfo.refreshToken
                },
            })
            .then((res) => {
                console.log(res.data)
                if (res.data.message === '게시글 카테고리 삭제에 성공하였습니다.') {
                    state.dispatch('getBoardCategoryList', {
                        payload: {
                            moduleSrl: payload.module_srl
                        }
                    })
                }
            })
            .catch((err) => {
                console.log("delBoardCategory Error :", err)
                if (!_.isEmpty(err.response)) {
                    if (err.response.status === 401) {
                        state.commit("accountStore/setCheckAuth", false, {root: true})
                    }
                }
            })
    }
}
