import {mapActions, mapMutations, mapState} from "vuex";

export const postStore = {
    ...mapState('postStore', {
        postTotalCnt: (state) => state.postTotalCnt,
        postVuexList: (state) => state.postVuexList,
        postVuexAnalyticsInfo: (state) => state.postVuexAnalyticsInfo,
        postDetail: (state) => state.postDetail,
        surveyContentList: (state) => state.surveyContentList,
    })
}

export const postMutations = {
    ...mapMutations('postStore', [
        'setPostSelect',
        'setResetPostList',
        'setVoteList',
        'setPostDetail',
        'setSurveyContentList'
    ])
}

export const postActions = mapActions('postStore', [
    'getPostingList', 'callForExcelDown', 'callForExcelDownForAnalytics' , 'getAnalyticsInfo'
])
